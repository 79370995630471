import "./App.css";
import { Hero } from "./Components/Hero";
import { Footer } from "./Components/Footer";

function App() {
  return (
    <>
      <Hero />
      <Footer />
    </>
  );
}

export default App;
